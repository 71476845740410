
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import getFallbackDiagram from '@/modules/editor/helpers/fallback-diagram'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { RouteModule } from '@/modules/route/store'

import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'DiagramGroupDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)

  @Ref() readonly dialog!: Dialog

  loading = false

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return 'latest'
  }

  get diagramGroupId () {
    return this.$queryValue('diagram_group_delete_dialog')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get diagramGroup () {
    return this.diagramGroupId ? this.diagramModule.diagramGroups[this.diagramGroupId] : undefined
  }

  get diagrams () {
    return Object.values(this.diagramModule.diagrams).filter(o => o.groupId === this.diagramGroupId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramGroup () {
    return this.currentDiagram?.groupId ? this.diagramModule.diagramGroups[this.currentDiagram.groupId] : undefined
  }

  get currentModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentModelParent () {
    return this.currentModel?.parentId ? this.modelModule.objects[this.currentModel.parentId] : undefined
  }

  opened () {
    analytics.diagramGroupDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.loading = false
  }

  async deleteDiagramGroup () {
    const diagramGroup = this.diagramGroup
    if (!diagramGroup) {
      return
    }

    try {
      this.loading = true

      const fallbackDiagram = getFallbackDiagram({
        diagram: this.currentDiagram,
        diagramGroupId: this.currentDiagramGroup?.id,
        diagramGroups: Object.values(this.diagramModule.diagramGroups).filter(o => o.id !== diagramGroup.id),
        diagrams: Object.values(this.diagramModule.diagrams).filter(o => o.groupId !== diagramGroup.id),
        model: this.currentModel,
        modelParent: this.currentModelParent
      })

      if (fallbackDiagram) {
        await this.$replaceQuery({
          connection: undefined,
          diagram: fallbackDiagram.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: fallbackDiagram.model.handleId,
          object: undefined,
          scale: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      } else {
        await this.$router.push({
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          }
        })
      }

      const diagramsIds = this.diagrams.map(o => o.id)

      await this.diagramModule.diagramGroupDelete({
        diagramGroupId: diagramGroup.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })

      this.editorModule.resetTaskLists()

      this.routeModule.pruneHistory(o => typeof o.query.diagram !== 'string' || !diagramsIds.includes(o.query.diagram))

      await this.$replaceQuery({
        diagram_group_delete_dialog: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
      throw err
    } finally {
      this.loading = false
    }
  }
}
