
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import getFallbackDiagram from '@/modules/editor/helpers/fallback-diagram'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { RouteModule } from '@/modules/route/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'DiagramDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)

  @Ref() readonly dialog!: Dialog

  loading = false

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return 'latest'
  }

  get diagramId () {
    return this.$queryValue('diagram_delete_dialog')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get diagram () {
    return this.diagramId ? this.diagramModule.diagrams[this.diagramId] : undefined
  }

  get diagramContent () {
    return this.diagramId ? this.diagramModule.diagramContents[this.diagramId] : undefined
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramGroup () {
    return this.currentDiagram?.groupId ? this.diagramModule.diagramGroups[this.currentDiagram.groupId] : undefined
  }

  get currentModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentModelParent () {
    return this.currentModel?.parentId ? this.modelModule.objects[this.currentModel.parentId] : undefined
  }

  opened () {
    if (this.diagram) {
      analytics.diagramDeleteDialog.track(this, {
        diagramType: this.diagram.type,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  closed () {
    this.loading = false
  }

  async deleteDiagram () {
    const currentLandscape = this.currentLandscape
    const diagram = this.diagram
    const diagramContent = this.diagramContent
    if (!diagram) {
      return
    }

    try {
      this.loading = true

      const fallbackDiagram = getFallbackDiagram({
        diagram: this.currentDiagram,
        diagramGroupId: this.currentDiagramGroup?.id,
        diagramGroups: Object.values(this.diagramModule.diagramGroups),
        diagrams: Object.values(this.diagramModule.diagrams).filter(o => o.id !== diagram.id && o.parentId !== diagram.id),
        model: this.currentModel,
        modelParent: this.currentModelParent
      })

      const revertTasks: Task[] = [{
        id: diagram.id,
        props: {
          connections: diagramContent?.connections,
          description: diagram.description,
          handleId: diagram.handleId,
          index: diagram.index,
          labels: diagram.labels,
          modelId: diagram.modelId,
          name: diagram.name,
          objects: diagramContent?.objects,
          parentId: diagram.parentId,
          status: diagram.status,
          tasksProposed: diagramContent?.tasksProposed.map(o => o.task),
          type: diagram.type
        },
        type: 'diagram-create'
      },
      ...Object
        .values(this.flowModule.flows)
        .filter(o => o.diagramId === diagram.id)
        .map((o): Task => ({
          id: o.id,
          props: {
            diagramId: o.diagramId,
            handleId: o.handleId,
            labels: o.labels,
            name: o.name,
            showConnectionNames: o.showConnectionNames,
            steps: o.steps
          },
          type: 'flow-create'
        })),
      {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            diagram_delete_dialog: null
          }
        },
        type: 'navigation'
      }]

      if (fallbackDiagram) {
        await this.$replaceQuery({
          connection: undefined,
          diagram: fallbackDiagram.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_step: undefined,
          model: fallbackDiagram.model.handleId,
          object: undefined,
          scale: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      } else {
        await this.$router.push({
          name: 'diagrams',
          params: {
            landscapeId: currentLandscape.id,
            versionId: this.currentVersionId
          },
          query: {
            domain: this.$query.domain
          }
        })
      }

      const diagramType = diagram.type

      this.diagramModule.removeDiagram(diagram.id)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramDelete({
          diagramId: diagram.id,
          landscapeId: currentLandscape.id,
          versionId: 'latest'
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              diagram_delete_dialog: null
            }
          },
          type: 'navigation'
        }, {
          id: diagram.id,
          type: 'diagram-delete'
        }]
      })

      this.routeModule.pruneHistory(o => o.query.diagram !== diagram.handleId)

      this.alertModule.pushAlert({
        message: 'Diagram deleted'
      })

      analytics.diagramDelete.track(this, ({
        diagramType,
        landscapeId: [currentLandscape.id],
        organizationId: [currentLandscape.organizationId]
      }))

      this.dialog?.close()
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.status === 400 ? 'Error deleting diagram' : err.message
      })
      this.loading = false
    }
  }
}
